/** @jsxImportSource theme-ui */
import React, { useEffect, useState } from 'react'
import Chat from '../Chat'
import ChatInvitation from './ChatInvitation'
import { COLOR } from '@ndlib/component-library'
import ChatImage from './chat.png'

const ChatDrawer = () => {
  const LS_CHECK = 'proactiveChatDismiss'
  const TIME_DELAY = 1000 * 60 * 3 // 3 minutes
  const [isDrawerOpen, setDrawerOpen] = useState(false)
  const [isModalOpen, setModalOpen] = useState(false)
  
  const disableProactiveChat = () => {
    setModalOpen(false)
    try {
      window.localStorage.setItem(LS_CHECK, new Date())
    } catch (e) {
      // console.error(e)
    }
  }

  const toggleDrawerAndDisableProactiveChat = (state) => {
    setDrawerOpen(state)
    disableProactiveChat()
  }

  // Set a timer to show the chat modal prompt. If it has already been shown or otherwise disabled by opening the chat don't show it again.
  useEffect(() => {
    const timer = setTimeout(() => {
      if(!window.localStorage.getItem(LS_CHECK)) {
        setModalOpen(true)
      }
    }, TIME_DELAY);
    return () => clearTimeout(timer);
  }, []);



  const buttonLabel = [
    <span><img src={ChatImage} alt='' aria-hidden='true' sx={{ height: '1rem', verticalAlign: 'top'}} /> Chat with a librarian</span>,
    <span>Hide Chat</span>
  ]
  const buttonSx = {
    backgroundColor: COLOR.ALERT_INFORMATIONAL_YELLOW_BG,
    boxShadow: 'none',
    textDecoration: 'none',
    textTransform: 'none !important',
    textAlign: 'left',
    padding: '8px 20px',
    border: 'none',
    color: COLOR.ND_BLUE,
    display: 'inline-block',
    borderRadius: '3px 3px 0 0',
    fontWeight: 'bold',
    fontSize: '1em !important',
    fontFamily: 'sans-serif !important',
    cursor: 'pointer',
  }
  const openSx = {}
  const closedSx = {
    display: 'none'
  }
  return (
    <div sx= {{
      position: 'fixed',
      right: '2em',
      bottom: '0px',
      zIndex: '200',
    }}>
      <button 
        onClick={() => toggleDrawerAndDisableProactiveChat(!isDrawerOpen)}
        onKeyDown={() => toggleDrawerAndDisableProactiveChat(!isDrawerOpen)}
        tabIndex={0}
        href='/chat'
        aria-haspopup='true'
        aria-owns='chat-modal'
        aria-expanded={isDrawerOpen}
        sx={buttonSx}
      >{buttonLabel[+isDrawerOpen]}</button>
      <div 
        id='chat-modal'
        role='tabpanel' 
        sx={isDrawerOpen ? openSx : closedSx}>
        <Chat />
      </div>
      {
        <ChatInvitation isOpen={isModalOpen} close={disableProactiveChat} />
      }
      
    </div>
  )
}
export default ChatDrawer
export const STRAPI_API_URL = process.env.STRAPI_API_URL || "https://library-website-cms.library.nd.edu"
export const STRAPI_TOKEN = process.env.STRAPI_TOKEN
export const NODE_ENV = process.env.NODE_ENV

export const LIBCAL_GATEWAY_API = "https://libcal-gateway.library.nd.edu";
export const ONE_SEARCH_API = 'https://onesearch.library.nd.edu/'
export const LIBCAL_BOOKING_LINK = "https://libcal.library.nd.edu/spaces"

export const GRAPHQL_ENDPOINT = new URL('/graphql', STRAPI_API_URL).toString()

export const FLOOR_SEARCH_API =  new URL('/map', 'https://call-number-maps.library.nd.edu')
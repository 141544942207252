/** @jsxImportSource theme-ui */
import * as React from "react"
import { Link } from "@ndlib/component-library"
import { COLOR } from "@ndlib/component-library"
import { formatPhoneLink } from '../../constants/HelperFunctions'
import ServiceNowLink from "../../components/Interactive/ServiceNowLink"
import Logo from './images/library.logo.png'
import Facebook from './images/facebook.png'
import Twitter from './images/x.logo_360.png'
import Linkedin from './images/linkedin.png'
import Instagram from './images/instagram.png'
import fdlpIcon from './images/fdlp-emblem-color.png'

const Footer = () => {
  const footerWrapper = {
    backgroundColor: COLOR.ND_BLUE,
    marginTop: '2rem',
    display: 'block',
    '@media screen and (max-width: 780px)': {
      display: 'flex',
      flexDirection: 'column-reverse',
    }
  }
  const allBox = {
    backgroundColor: COLOR.ND_BLUE,
    boxSizing: 'border-box',
    color: COLOR.TEXT_ON_PRIMARY,
    display: 'inline-block',
    fontFamily: 'GPCMed, sans-serif',
    fontWeight: '100',
    minHeight: '300px',
    padding: '3rem 2rem',
    verticalAlign: 'top',
    
    'p': {
      marginBlockStart: '0',
      marginBlockEnd: '.5rem'
    },
    'a': {
      color: COLOR.TEXT_ON_PRIMARY,
      textDecoration: 'none',

    },
    'ul': {
      paddingInlineStart: '0px',
      'li': {
        lineHeight: '1rem',
        listStyle: 'none',
        mb: ['0.25rem', '0.75rem', '0.75rem'],
      }
    }
  }
  const leftBox = {
    ...allBox,
    width: '66.666%',
    '@media screen and (max-width: 780px)': {
      width: '100%',
      p: '2rem',
    }
  }
  const rightBox = {
    ...allBox,
    backgroundColor: COLOR.ND_PROVOST_BLUE,
    width: '33.333%',
    '@media screen and (max-width: 780px)': {
      width: '100%',
      p: '2rem',
      pb: '1rem',
    }
  }
  const socialImage = {
    height: '1rem',
  }
  return (
    <footer sx={footerWrapper}>
      <div sx={leftBox}>
        <p>
          {/* eslint-disable max-len */}
          <Link to='https://www.google.com/maps/place/Theodore+M.+Hesburgh+Library/@41.7023619,-86.2363832,17z/data=!3m1!4b1!4m5!3m4!1s0x8816d29f1af60a29:0x87f74f541c574744!8m2!3d41.7023579!4d-86.2341945'>
            284 Hesburgh Library, Notre Dame, IN 46556
          </Link>
          {/* eslint-enable max-len */}
        </p>
        <p>Circulation Desk Phone <a href={formatPhoneLink('5746316679')}>(574) 631-6679</a>
        </p>
        <p>Security Monitors Phone <a href={formatPhoneLink('5746316350')}>(574) 631-6350</a>
        </p>
        <p>
          <a href="mailto:asklib@nd.edu" target="_blank" rel="noopener noreferrer">asklib@nd.edu</a>
        </p>
        <div sx={{mt: 4}}>
          <Link
            to='https://www.facebook.com/NDLibraries/'
            title='Hesburgh Libraries Facebook'
            target='_blank'
            rel='noopener'
          >
            <img src={Facebook} alt='Facebook' sx={socialImage} />
          </Link>
          &nbsp;&nbsp;
          <Link
            to='https://www.instagram.com/ndlibraries/'
            title='Hesburgh Libraries Instagram'
            target='_blank'
            rel='noopener'
          >
            <img src={Instagram} alt='Instagram' sx={socialImage} />
          </Link>
          &nbsp;&nbsp;
          <Link
            to='https://www.linkedin.com/company/ndlibraries/'
            title='Hesburgh Libraries LinkedIn'
            target='_blank'
            rel='noopener'
          >
            <img src={Linkedin} alt='LinkedIn' sx={socialImage} />
          </Link>
          &nbsp;&nbsp;
          <Link
            to='https://twitter.com/ndlibraries'
            title='Hesburgh Libraries Twitter'
            target='_blank'
            rel='noopener'
          >
            <img src={Twitter} alt='Twitter' sx={socialImage} />
          </Link>
          &nbsp;&nbsp;
          NDlibraries
        </div>
        <p id='copyright' sx={{paddingTop: '2rem'}}>
          <Link to='https://www.nd.edu/copyright/'>Copyright</Link> © {new Date().getFullYear()} University of Notre Dame
        </p>
      </div>
      <div sx={rightBox}>
        <img 
          src={Logo} alt='Hesburgh Library Logo'
          sx={{ maxWidth: '300px', width: '100%'}}
        />
        <ul role='tablist' aria-label='Footer Menu' 
          sx={{
            columns: [1, 1, 2],
            WebkitColumnCount: [1, 1, 2],
            mt: 3,
          }}>
          <li role='tab'><ServiceNowLink isWebContent>Website Feedback</ServiceNowLink></li>
          <li role='tab'><Link to='/library-policies'>Library Policies</Link></li>
          <li role='tab'><Link to='http://librarygiving.nd.edu'>Library Giving</Link></li>
          <li role='tab'><Link to='/employment/'>Jobs</Link></li>
          <li role='tab'><ServiceNowLink>Report A Problem</ServiceNowLink></li>
          <li role='tab'><Link to='https://sites.google.com/nd.edu/hlemployeeportal/home'>Employee Portal</Link></li>
          <li role='tab'>
            <Link to='https://libguides.library.nd.edu/government-information' aria-label='Federal Depository Library'>
              <img
                src={fdlpIcon}
                alt='Logo of Federal Depository Library Project'
                sx={{ verticalAlign: 'sub', width: '20px'}}
              />&nbsp;&nbsp;Federal Depository Library Project
            </Link>
          </li>
        </ul>
      </div>
    </footer>
  )
}

export default Footer
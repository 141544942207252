import { Box, StylesProp } from '@ndlib/component-library'
import * as React from 'react'

const sxStyle: StylesProp = {
  boxSizing: 'border-box',
  margin: '0 auto',
  px: [4, 5, 5, 5, 2],
  width: '100%',
  '@media screen and (min-width: 1212px)': {
    maxWidth: '1200px',
    padding: '0',
  }
}
const MaximumReadableWidth: React.FC<React.PropsWithChildren<{
  sx?: StylesProp
}>> = ({children, sx}) => {
  return (
    <Box sx={{...sxStyle, ...sx}}>
      {children}
    </Box>
  )
}

export default MaximumReadableWidth
import { graphql, useStaticQuery } from 'gatsby'
import { MENU_ACTION_TYPE, MENU_ORIENTATION } from '@ndlib/component-library'

type MenuItem = {
  id: string // a unique slug for this menu item,
  label: string // String that will be rendered to represent this option
  action: {
    type: MENU_ACTION_TYPE.LINK | MENU_ACTION_TYPE.SUBMENU
    to?: string // Link menu items need a URL to navigate to

    // Link menu items that trigger a submenu should specify the submenu
    // following the same type as the top level menu
    submenu?: Menu
  }
}

type Menu = {
  orientation: MENU_ORIENTATION.HORIZONTAL | MENU_ORIENTATION.VERTICAL
  items: MenuItem[]
}

type StrapiMenuItem = {
  title: string
  path: string
  slug: string
  menuAttached?: boolean
  items?: StrapiMenuItem[]
  related?: {
    title: string
    slug: string
    shortDescription: string
    dynamicPage: string
    url: string
    description: string
  }
}

const transformStrapiMenu = (items: StrapiMenuItem[]): Menu => {
  const transformedMenu: Menu = {
    orientation: MENU_ORIENTATION.HORIZONTAL,
    items: []
  }

  // Add a Home link to the beginning of the menu
  const homeMenuItem: MenuItem = {
    id: 'home',
    label: 'Home',
    action: {
      type: MENU_ACTION_TYPE.LINK,
      to: '/'
    }
  }

  transformedMenu.items.push(homeMenuItem)

  // Add Research, Services, Libraries, and About menu items
  // console.log('getMenuItems:', items)
  for (const item of items) {
    const menuItemLevel1: MenuItem = {
      id: item.path,
      label: item.title,
      action: {
        type: MENU_ACTION_TYPE.SUBMENU,
        submenu: {
          orientation: MENU_ORIENTATION.HORIZONTAL,
          items: []
        }
      }
    }

    for (const subItem of item.items!) {
      const menuItemLevel2: MenuItem = {
        id: subItem.path,
        label: subItem.title,
        action: {
          type: MENU_ACTION_TYPE.SUBMENU,
          submenu: {
            orientation: MENU_ORIENTATION.VERTICAL,
            items: []
          }
        }
      }

      for (const leafItem of subItem.items!) {
        if (leafItem.menuAttached) {
          // Check if menuAttached is true
          const menuItemLevel3: MenuItem = {
            id: leafItem.path,
            label: leafItem.title,
            action: {
              type: MENU_ACTION_TYPE.LINK,
              to: leafItem?.url_override
            }
          }

          menuItemLevel2.action.submenu!.items.push(menuItemLevel3)
        }
      }
      if (menuItemLevel2.action.submenu!.items.length > 0) {
        menuItemLevel1.action.submenu!.items.push(menuItemLevel2)
      }
    }

    if (menuItemLevel1.action.submenu!.items.length > 0) {
      transformedMenu.items.push(menuItemLevel1)
    }
  }

  // Add a Search link to the beginning of the menu
  const searchMenuItem: MenuItem = {
    id: 'search',
    label: 'Search',
    action: {
      type: MENU_ACTION_TYPE.LINK,
      to: '/search'
    }
  }

  transformedMenu.items.push(searchMenuItem)

  // Add a Log In link to the menu
  const loginMenuItem: MenuItem = {
    id: 'login',
    label: 'Log In',
    action: {
      type: MENU_ACTION_TYPE.LINK,
      to: '/login'
    }
  }

  transformedMenu.items.push(loginMenuItem)

  // Add a link to the Hours page in the menu
  const hoursMenuItem: MenuItem = {
    id: 'hours',
    label: 'Hours',
    action: {
      type: MENU_ACTION_TYPE.LINK,
      to: '/hours'
    }
  }

  transformedMenu.items.push(hoursMenuItem)

  return transformedMenu
}

export const getMenuItems = (): Menu => {
  const data = useStaticQuery(graphql`
    query {
      allStrapiNavigationPluginResearch {
    nodes {
      items {
        title
        slug
        path
        items {
          title
          slug
          path
          url_override
          menuAttached
          items {
            title
            slug
            path
            url_override
            menuAttached
            related {
              title
              slug
              shortDescription
              url
              description
            }
          }
        }
      }
    }
  }
  allStrapiNavigationPluginServices {
    nodes {
      items {
        title
        slug
        path
        items {
          title
          slug
          path
          url_override
          menuAttached
          items {
            title
            slug
            path
            url_override
            menuAttached
            related {
              title
              slug
              shortDescription
              dynamicPage
              url
              description
            }
          }
        }
      }
    }
  }
  allStrapiNavigationPluginLibraries {
    nodes {
      items {
        title
        slug
        path
        items {
          title
          slug
          path
          url_override
          menuAttached
          items {
            title
            slug
            path
            url_override
            menuAttached
            related {
              title
              slug
              shortDescription
              dynamicPage
              url
              description
            }
          }
        }
      }
    }
  }
  allStrapiNavigationPluginAbout {
    nodes {
      items {
        title
        slug
        path
        items {
          title
          slug
          path
          url_override
          menuAttached
          items {
            title
            slug
            path
            url_override
            menuAttached
            related {
              title
              slug
              shortDescription
              dynamicPage
              url
              description
            }
          }
        }
      }
    }
  }
  }
  `)

  // Initialize an empty array for menu items.
  let items: StrapiMenuItem[] = []

  // Function to add items from a data node to the items array.
  const addItems = (dataNode: any) => {
    const itemsToAdd = dataNode?.nodes[0].items || []
    items.push(...itemsToAdd)
  }

  // Add items from research, services, libraries, and about to the items array.
  addItems(data.allStrapiNavigationPluginResearch)
  addItems(data.allStrapiNavigationPluginServices)
  addItems(data.allStrapiNavigationPluginLibraries)
  addItems(data.allStrapiNavigationPluginAbout)

  // Transform and return the items.
  return transformStrapiMenu(items)
}

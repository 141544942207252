// Helper functions are not something that should need regular maintenance. They should "just work",
// and their inner-workings often need to be complex to accommodate generic usage.
export const formatPhoneLink = (phoneNumber) => {
  // The plus allows it to work for international numbers, in theory
  let output = 'tel:+'
  // Remove all non-digits
  const sanitized = phoneNumber.replace(/[^\d]/g, '')
  // If it is 10 digits long, we assume it is a US number and does NOT include the country code.
  if (sanitized.length === 10) {
    output += '1'
  }
  output += sanitized
  return output
}

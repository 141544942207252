import * as React from 'react'
import {
  Alerts,
  Box,
  BrandingBar,
  Group,
} from '@ndlib/component-library'
import HesburghBranding from './HesburghBranding'
import MainNavigation from './MainNavigation'
import Footer from './Footer'
import ChatDrawer from '../components/Interactive/ChatDrawer'


const Layout: React.FC<React.PropsWithChildren<{
  pageContext: any
}>> = ({ children, pageContext }) => {
  return (
    <Box style={{ position: 'relative' }}>
      <BrandingBar />
      <HesburghBranding />
      <MainNavigation />
      <Alerts data-testid='ALERT_TEST' />
      <Group sx={{mt: 0}}>
        {children}
      </Group>
      <Footer />
      <ChatDrawer />
    </Box>
  )
}

export default Layout

import { UiProvider } from "@ndlib/component-library"
import { AuthProvider } from "./AuthProvider"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import { SharedStateProvider } from "./SharedState"
import React from "react"
import { GRAPHQL_ENDPOINT } from "../../config"

export const MergedProvider: React.FC<{
  element: React.ReactNode,
  testMode: boolean
}> = ({ element, testMode }) => {
  const queryClient = new QueryClient()

  const Main = (
    <UiProvider
      loadFonts
      loadGlobalStyles
      useHydrationPatch
      ssrBreakpoint={5}
      alertsConfig={{
        disable: testMode,
        endpoint: GRAPHQL_ENDPOINT,
      }}
    >
      <QueryClientProvider client={queryClient}>
        <SharedStateProvider>
          {element}
        </SharedStateProvider>
      </QueryClientProvider>
    </UiProvider>
  )

  if (testMode) return Main

  return (
    <AuthProvider>
      {Main}
    </AuthProvider>
  )
}

import React, { FC, ReactNode } from 'react'
import { Link } from "@ndlib/component-library"

interface ServiceNowLinkProps {
  children?: ReactNode
  isWebContent?: boolean | null
}
const ServiceNowLink =({ children, isWebContent }:ServiceNowLinkProps) => {
  const serviceNowBaseURL = 'https://nd.service-now.com/nd_portal?id=sc_cat_item&sys_id=1198d67ddb4a7240de73f5161d961936'
  let url = serviceNowBaseURL
  if (typeof window !== "undefined") {
    url = `${serviceNowBaseURL}&URL=${window.location}`
    if (isWebContent) {
      url += '&lib_list_problem=lib_list_web_content'
    }
  }

  return (
    <Link to={url}>
      {children}
    </Link>
  )
}

export default ServiceNowLink

/** @jsxImportSource theme-ui */
import React from 'react'
import { navigate } from 'gatsby'
import { Column, Modal, Paragraph, PARAGRAPH_SIZE } from '@ndlib/component-library'

const inviteSx = {
  backgroundColor: '#fff',
  boxShadow: 'rgba(0, 0, 0, 0.5) 0px 5px 15px',
  inset: 'auto 20px 40px auto !important',
  marginRight: 'auto !important',
  maxWidth: '400px !important',
  minWidth: '300px !important',
  position: 'fixed',
  transform: 'none !important',
  zIndex: '1000',
}

const ChatInvitation = ({isOpen, close}) => {
  return (
    <Modal
      isOpen={isOpen}
      heading='Need Help?'
      close={close}
      sx={inviteSx}
      bodyOpenClassName='modal-allow-scroll'
      overlayClassName='modal-overlay-transparent'
      shouldFocusAfterRender
      shouldReturnFocusAfterClose
      actions={[
          {
            label: 'No thanks',
            isPrimary: false,
            onClick: close,
          },
          {
            label: 'Click to chat',
            isPrimary: true,
            onClick: () => {
              close()
              navigate('/chat')
            },
          },
        ]}
    >
      <Column>
        <Paragraph size={PARAGRAPH_SIZE.MD} sx={{ margin: '1rem' }}>
          Chat with us now.
        </Paragraph>
      </Column>
    </Modal>
  )
}

export default ChatInvitation

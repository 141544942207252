import React from 'react'

import "./src/assets/layout.css"
import { MergedProvider } from './src/components/Providers'

export const wrapRootElement = ({ element, props }) => {
  return (
    <MergedProvider element={element} />
  )
}

export const shouldUpdateScroll = ({prevRouterProps, routerProps}) => {
  // console.log('not scrolling');
  // window.scrollTo(0, 0)
  return true;
}
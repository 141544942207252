import React, { useEffect } from "react"

type SharedState = {
  [key: string]: any
}

type SharedStateContextType = {
  getState: () => SharedState,
  updateState: (key: string, value: any) => void,
  addSubscription: (key: string, subscription: Function) => void
  removeSubscription: (key: string, subscription: Function) => void
}

const SharedStateContext = React.createContext<SharedStateContextType>({
  getState: () => ({}),
  updateState: () => {},
  addSubscription: () => {},
  removeSubscription: () => {}
})

export const SharedStateProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const sharedState = React.useRef<SharedState>({})
  const subscriptions = React.useRef<{
    [key: string]: Function[]
  }>({})

  const updateState = (key, value) => {
    sharedState.current[key] = value
    subscriptions.current[key]?.forEach((subscription) => {
      subscription()
    })
  }

  const getState = () => {
    return sharedState.current
  }

  const addSubscription = (key, subscription) => {
    if (!subscriptions.current[key]) {
      subscriptions.current[key] = []
    }
    subscriptions.current[key].push(subscription)
  }

  const removeSubscription = (key, subscription) => {
    subscriptions.current[key] = subscriptions.current[key].filter((s) => s !== subscription)
  }

  return (
    <SharedStateContext.Provider
      value={{
        getState,
        updateState,
        addSubscription,
        removeSubscription
      }}
    >
      {children}
    </SharedStateContext.Provider>
  )
}

export function useSharedState<T> (key: string, initialValue: T): [T, (value: T) => void] {
  const {
    getState,
    updateState: updateSharedState,
    addSubscription,
    removeSubscription
  } = React.useContext(SharedStateContext)

  const [,updateForRender] = React.useState(0)
  const forceRerender = () => {
    updateForRender((value) => value + 1)
  }

  useEffect(() => {
    const subscription = () => {
      forceRerender()
    }

    addSubscription(key, subscription)

    return () => {
      removeSubscription(key, subscription)
    }
  })

  const updateState = (value: T) => {
    updateSharedState(key, value)
  }

  const storedValue = getState()[key] || initialValue

  return [
    storedValue as T,
    updateState
  ]
}
  

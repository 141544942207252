import OktaAuth, { IDToken } from "@okta/okta-auth-js";
import React, { PropsWithChildren, useCallback, useEffect, useMemo, useState } from "react";

import { logError } from "../../utils/logError";
import { navigate } from "gatsby";

const STUB_CONFIG = {}

const getConfig = () => {
  if (typeof window !== 'undefined') {
    return {
      clientId: '0oa1sgzudbJePlVps357', //process.env.AUTH_CLIENT_ID,
      redirectUri: `${window.location.origin}/`,
      issuer: process.env.AUTH_CLIENT_ISSUER,
      url: process.env.AUTH_CLIENT_URL,
      ignoreSignature: true,
      tokenManager: {
        secure: true,
        storage: 'localStorage',
        storageKey: 'libraryWebsite',
        autoRenew: true,
        autoRemove: true
      },
      responseType: 'id_token',
      pkce: false,
    } as const
  } else {
    return STUB_CONFIG
  }
}

export const stubAuthClient = {
  token: {
    getWithRedirect: () => {},
  },
  tokenManager: {
    get: () => {}
  }
}

const useAuthClient = () => {
  // The `OktaAuth` constructor can throw if the config is malformed
  const config = getConfig()

  const authClient = useMemo(() => {
    if (config === STUB_CONFIG) {
      return null
    }

    try {
      const authClient = new OktaAuth(config);

      authClient
        .start()
        .then(() => {
          // This is a patch to ensure that the token is renewed when it expires
          // This hypothetically should be handled by the tokenManager.autoRenew
          // But this feature is buggy (open issue)
          const tokenRenewListener = (key) => {
            authClient.tokenManager.renew(key);
          };

          authClient.tokenManager.on('expired', (k) => {
            tokenRenewListener(k)
          })
        })

      return authClient
    } catch (error) {
      logError(`Auth client instantiation failed with error: ${error}`);
    }
  }, [])

  return authClient;
}

const mockAuthClient = {
  token: {
    getWithRedirect: () => {},
  },
} as OktaAuth;

type AuthData = {
  name: string;
  email: string;
  netid: string;
}

const AuthContext = React.createContext<{
  authClient: OktaAuth;
  logIn: () => void;
  logOut: () => void;
  authData: AuthData | null | undefined;
  getToken: () => Promise<string | null>; // Undefined when retrieving token, null if no token found
}>({
  authClient: mockAuthClient,
  logIn: () => {},
  logOut: () => {},
  authData: undefined,
  getToken: () => Promise.resolve(null),
})

export const AuthProvider: React.FC<PropsWithChildren> = ({ children }) => {
  // const [isFetching, setIsFetching] = React.useState(false)
  const [authData, setAuthData] = useState<AuthData | null | undefined>(undefined);

  const authClient = useAuthClient() || mockAuthClient;

  const updateAuthData = (token: IDToken) => {
    if (token && !authData) {
      const { name, email, netid } = token.claims
      setAuthData({ name, email, netid } as any)
    }
  }

  const logIn = useCallback(() => {
    // Save the user's requested url to local storage so we can redirect them after authenticating
    try {
      window.localStorage.setItem('redirectUrl', window.location.href)
    } catch (e) {
      console.warn('Local storage is not available.')
    }

    console.log('logging in', authClient.token)

    authClient.token.getWithRedirect({
      responseType: 'id_token',
      scopes: [
        'openid',
        'profile',
        'email',
        'netid',
      ],
    })
  }, [])

  const logOut = useCallback(() => {
    // remove the jwt login information in session storage
    localStorage.removeItem('libraryWebsite')
    authClient.signOut()
    setAuthData(null)
  }, [])

  const getToken = async () => {
    try {
      const token = await authClient.tokenManager.get('idToken') as IDToken

      if (token) {
        handleToken(token)

        return token.idToken

        // If ID Token isn't found, try to parse it from the current URL
      } else if (window.location.hash) {
        if (window.location.hash) {
          const response = await authClient.token.parseFromUrl()

          const { idToken } = response.tokens

          if (idToken) {
            authClient.tokenManager.add('idToken', idToken)
            handleToken(idToken)
          }

          return idToken?.idToken || null
        }

      } else {
        setAuthData(null)
        return null
      }
    } catch (error) {
      logError(`finding token failed with error: ${error}`)
    }

    return null
  }

  const handleToken = (token: IDToken) => {
    updateAuthData(token)

    try {
      const redirectUrl = window.localStorage.getItem('redirectUrl')

      if (redirectUrl) {
        // Remove it so that subsequent requests for the token do not cause redirects
        window.localStorage.removeItem('redirectUrl')
      }

      // Now redirect the browser if the redirectUrl is different from the current url
      if (redirectUrl && redirectUrl !== window.location.href) {
        window.location.assign(redirectUrl)
      }
    } catch (e) {
      console.warn('Session storage is not available.')
    }
  }

  useEffect(() => {
    getToken()
  }, [])

  return (
    <AuthContext.Provider value={{
      authClient,
      logIn,
      logOut,
      authData,
      getToken
    }}>
      {children}
    </AuthContext.Provider>
  )
}

export const useAuth = () => React.useContext(AuthContext)

export const useLeavePageOnLogOut = () => {
  const { authData } = useAuth()

  useEffect(() => {
    if (authData === null) {
      navigate('/')
    }
  })
}

